/* App.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .container {
    max-width: 500px !important;
    margin: 30px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .resume_title{
    display: flex;
    justify-content: center;
    
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px !important;
    font-weight: bold;
  }
  
  input[type='checkbox'] {
    margin-right: 10px;
    margin-bottom: 5px !important;
  }
  
  input[type='number'],
  input[type='text'],
  select {
    width: calc(100% - 0px); /* Adjust width considering padding and border */
    padding: 10px;
    margin-bottom: 10px !important;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  select {
    appearance: none;
  }
  .optionPlaceholder {
    color: #888 !important; /* Set your desired placeholder color */
    font-style: italic; /* Make it italic or define other placeholder-like styles */
  }
  
  input:focus,
  select:focus {
    outline: none;
    border-color: #2468a2;
    box-shadow: 0 0 5px #2468a2;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #2468a2 !important;
    color: white !important;
    cursor: pointer !important;
    margin-bottom: 10px !important;
  }
  
  button:hover {
    background-color: #1a5173 !important;
  }
  